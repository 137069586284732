.mainmedia {
    background-color: #f7f7f7;
    padding-bottom: 10%;
  
  }
  .blog img {
    transition: 0.5s;
    width: 100%;
    height: 100%;
  }

  /* .blog .img {
    position: relative;
    overflow: hidden; 
    width: 100%;
    height: 100%;
  } */
  .allmediatop{
    padding:25px;
  }
  .movie_img {
    border-radius:12px;
  }
  .blog i {
    position: absolute;
    top: 45%;
    left: 45%;
    z-index: 5;
    font-size: 40px;
    color: white;
    opacity: 0;
    transition: 0.5s ease-in-out;
}
  .blog .img::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 0;
    transition: 0.5s ease-in-out;
}
    .blog .img::after {
      content: "";
      position: absolute;
      top: 10px;
      left: 10px;
      width: 90%;
      height: 90%;
      z-index: 3;
      border: 1px solid #fff;
      opacity: 0;
      transition: 0.5s ease-in-out;
  }
    .blog .items:hover {
      transform: scale(1.1);
      cursor: pointer;
    }
    .blog .items {
      padding: 20px;
      border-radius: 5px;
      transition: 0.5s ease-in-out;
      width: 100%;
      /* margin-left: -30%; */
    }
    .headingMedia {
      margin-left: 7%;
      padding: 50px 0 0px 0;
      margin-top: -35%;
    }
    .category span {
      font-size: 14px;
      color: grey;
      transition: 0.5s ease-in-out;
    }
    .category label {
      background-color: #31b675;
      font-size: 13px;
      color: white;
      padding: 5px 15px;
      border-radius: 5px;
      transition: 0.5s ease-in-out;
    }
    .movie-btn {
      margin-bottom: 3%;
      /* margin-left: 45%; */
      padding: 10px 20px;
      background-color: transparent;
      color: white;
      font-weight: 400;
      transition: 0.5s;
      border-radius: 25px;
      border-color: white;
      border: solid;
      margin-top: -10%;
    }
    .gridMedia {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-gap: 0%;
    }
    .blog .items h3 {
      margin: 5px 0 10px 0;
      font-weight: 500;
      transition: 0.5s ease-in-out;
    }
    .blog .items p {
      font-size: 25px;
      color: lightgrey;
      margin-bottom: 10px;
      transition: 0.5s ease-in-out;
    }
    .mimg {
      position: relative;
      height: 70%;
      /* width: 100%; */
  }

    @media screen and (min-width:1025px) and (max-width: 1280px) {
      .blog img {
        width: 100%;
        height: 100%;
        border-radius: 5px;
        margin-bottom: 20px;
        margin-top: -8%;
      }
    }
   @media screen and (min-width:993px) and (max-width: 1024px) {
    .blog img {
      width: 100%;
      height: 100%;
      border-radius: 5px;
      margin-bottom: 20px;
      margin-top: -10%;
    }
  }
   @media screen and (min-width:769px) and (max-width: 992px) {
    
      .gridMedia {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 5%;
      }
      .movie-btn {
        margin-left: 45%;
        padding: 10px 20px;
        background-color: transparent;
        color: white;
        font-weight: 400;
        transition: 0.5s;
        border-radius: 25px;
        border-color: white;
        border: solid;
        margin-top: 15%;
      }
    }
    @media screen and (min-width:577px) and (max-width: 768px) {
      .movie-btn {
        margin-left: 40%;
        padding: 10px 20px;
        background-color: transparent;
        color: white;
        font-weight: 400;
        transition: 0.5s;
        border-radius: 25px;
        border-color: white;
        border: solid;
        margin-top: 10%;

      }
      .gridMedia {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 12px;
      }
      .headingMedia {
        margin-left: 7%;
        margin-top: -50%;
      }
      .blog .items {
        padding: 20px;
        border-radius: 5px;
        transition: 0.5s ease-in-out;
        margin-left: 0%;
        width: 100%;
      }
      .blog .items p {
        font-size: 25px;
        color: lightgrey;
        margin-bottom: 10px;
        transition: 0.5s ease-in-out;
        margin-top: 5%;
      }
    }
    @media screen and (min-width:270px) and (max-width: 576px) {
     
      .blog .img {
        position: relative;
        overflow: hidden;
        width: 100%;
        height: 100%;
        padding-left: 10%;
    }
      .gridMedia {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 0px;
        /* margin-left: -6%; */
  
      }
      .headingMedia {
        margin-left: 7%;
        margin-top: -50%;
      }
      .blog .items {
        padding: 20px;
        border-radius: 5px;
        transition: 0.5s ease-in-out;
        width: 100%;
        margin-left: 0%;
      }
      .movie-btn {
        margin-left: 30%;
        padding: 10px 20px;
        background-color: transparent;
        color: white;
        font-weight: 400;
        transition: 0.5s;
        border-radius: 25px;
        border-color: white;
        border: solid;
        margin-bottom: 5%;
        margin-top: 15%;
    }
    }
     /* @media screen and (max-width: 820px) {
    
      .gridMedia {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 100px;
      }
    }
     */