.bg {
    background-image: url('../../../../public/images/About_bg.png');
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 100vh;
    
}

.container {
    margin-left: 5%;
    margin-right: 5%;
    margin-bottom: 3%;
    color: white;
}

.about-heading {
    /* position: absolute; */
    /* left: 12.03%;
    right: 61.35%;
    top: 28.28%;
    bottom: 69.21%; */

    /* font-family: 'Inter'; */
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
    line-height: 85px;
    text-align: left;
    color: #FF8000;
    font-family: "Helvetica";
}

.about-para {
    text-align: justify;
    /* font-family: "Inter"; */
    color: #D9D9D9;
    font-family: "ImagoTTBoo";
}

.founder-img{
    width: 90%;
}

@media screen and (min-width: 280px) and (max-width: 768px) {

    .container {
        margin-left: 2%;
        margin-right: 1%;
        margin-bottom: 3%;
        color: white;
    }
}