@font-face {
  font-family: 'Imago';
  src: local('Imago'), url(./Fonts/Fonts/Imago/ImagoTTBoo.ttf) format('truetype');
}
@font-face {
  font-family: 'Helvetica';
  src: local('Helvetica'), url(./Fonts/helvetica-neue/HelveticaNeue-Light.otf) format('truetype');
}
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  
}
body {
  /* font-family: "Poppins", Imago; */
  font-family: "Imago";
  
}
a {
  text-decoration: none;
}
.flex {
  display: flex;
}
.flex_space {
  display: flex;
  justify-content: space-between;
}
.container {
  max-width: 100%;
  margin: auto;
  /* background-color: darkred; */
}
.container1 {
  max-width: 100%;
  margin: auto;
  background-color: black;
}
li {
  list-style-type: none;
}
button {
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
}
.primary-btn {
  padding: 10px 20px;
  background-color: transparent;
  color: white;
  font-weight: 400;
  transition: 0.5s;
  border-radius: 25px;
  border-color: white;
  border: solid;
}
.primary-btn:hover {
  background-color: #000;
  color: white;
}
.secondary-btn {
  padding: 12px 30px;
  background-color: #31b675;
  color: white;
  font-weight: 400;
  font-size: 15px;
  margin-top: 20px;
}
.outline-btn {
  padding: 12px 30px;
  background-color: #fff;
  color: black;
  font-weight: 400;
  font-size: 15px;
  margin-top: 20px;
  border: 2px solid #000;
}
.secondary-btn i {
  margin: 7px 0 0 10px;
}

.control-btn button {
  background-color: rgba(255, 255, 255, 0.3);
  width: 60px;
  height: 60px;
  line-height: 60px;
  text-align: center;
  border-radius: 50%;
  color: white;
  cursor: pointer;
}
.control-btn i {
  background-color: #31b675;
  width: 45px;
  height: 45px;
  margin: auto;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color: white;
  font-size: 30px;
}
input {
  width: 100%;
  padding: 20px;
  outline: none;
  /* border: none; */
  margin: 10px;
  border-radius: 35px;
}
.mediabgimage {
  width: 50%;
  margin-left: 50%;
}
.row-about {
  width: 50%;
}
.top {
  margin-top: 100px;
}
.mtop {
  margin-top: 50px;
}
.allmediatop {
  margin-top: 50px;
  overflow: hidden;
}
.heading {
  text-align: center;
  padding: 50px 0 50px 0;
}
.heading h1 {
  font-size: 50px;
  font-weight: 500;
  color: #FF8000;
  text-align: left;
  margin-left: 10%;
}
.heading .line {
  width: 18%;
  margin: auto;
  margin-top: 20px;
  border-bottom: 2px dashed #999999;
  position: relative;
}
.heading .line::after {
  content: "";
  position: absolute;
  width: 15px;
  height: 15px;
  background-color: #31b675;
  border-radius: 50%;
  top: -6px;
  left: -20px;
}
.heading .line::before {
  content: "";
  position: absolute;
  width: 15px;
  height: 15px;
  background-color: #31b675;
  border-radius: 50%;
  top: -6px;
  right: -20px;
}
.full_container {
  max-width: 95%;
  margin: auto;
}
.main-content {
  width: 55%;
}
.side-content {
  width: 30%;
  margin-left: 40px;
  font-weight: bold;
  font-family: "Imago";

}
.grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 85px;
}
.grid1 {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}
.icon {
  margin-top: 20px;
}
.icon i {
  background: lightgray;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-radius: 5px;
}



/* 
@media screen and (max-width: 820px) {
  .container {
    max-width: 90%;
  }
  .grid {
    grid-template-columns: repeat(2, 1fr);
  }
  .gridMedia {
    grid-template-columns: repeat(2, 1fr);
  }
  .main-content {
    width: 100%;
  }
  .side-content {
    width: 100%;
    margin-left: 0px;
  }
 
} */

@media screen and (min-width: 769px) and (max-width: 992px) {
  .container {
    max-width: 100%;
  }
  .grid {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 60px;

  }
  .gridMedia {
    grid-template-columns: repeat(2, 1fr);
  }
  .main-content {
    width: 100%;
  }
  .side-content {
    width: 100%;
    margin-left: 0px;
  }
 
}
@media screen and (min-width: 577px) and (max-width: 768px) {
  .container {
    max-width: 100%;
  }
  .grid {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 60px;

  }
  .gridMedia {
    grid-template-columns: repeat(2, 1fr);
  }
  .main-content {
    width: 100%;
  }
  .side-content {
    width: 100%;
    margin-left: 0px;
  }
 
}

@media screen and (min-width: 280px) and (max-width: 576px) {
  .allmediatop {
    margin-top: 18px;
}
  .container {
    max-width: 100%;
  }
  .grid {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 60px;
  }
  .gridMedia {
    grid-template-columns: repeat(2, 1fr);
  }
  .main-content {
    width: 100%;
  }
  .side-content {
    width: 100%;
    margin-left: 0px;
  }
  .primary-btn {
  padding: 10px 10px;
  background-color: transparent;
  color: white;
  font-weight: 400;
  transition: 0.5s;
  border-radius: 25px;
}
.heading h1 {
  font-size: 30px;
  font-weight: 500;
  color: #FF8000;
  text-align: left;
  margin-left: 10%;
}
  
 
}
/*
@media screen and (max-width: 468px) {
  .container {
    max-width: 90%;
  }
  .grid {
    grid-template-columns: repeat(2, 1fr);
  }
  .gridMedia {
    grid-template-columns: repeat(2, 1fr);
  }
  .main-content {
    width: 100%;
  }
  .side-content {
    width: 100%;
    margin-left: 0px;
  }
 
} */