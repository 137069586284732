.originals_gallery{
    margin-left: 2%;

}

.originalgallery_grid{
    /* display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 2%; */

    width: 100%;
    display: flex;
    flex-wrap: wrap;
    /* grid-template-columns: repeat(auto-fit, minmax(250px,1fr)); */
}