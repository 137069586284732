.movie-title {
    font-size: 100px;
    color: #FF8000;
    margin: 4%;
}

.movie-gallery {
    /* width:100%; */
    /* display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px,1fr));
    grid-template-rows: repeat(3,minmax(250px, 1fr)); */

    margin: 0;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    width: 32.5%;
}

.movie-btn {
    padding: 10px 20px;
    background-color: transparent;
    color: white;
    font-weight: 400;
    transition: 0.5s;
    border-radius: 25px;
    border-color: white;
    border: solid;
    padding-left: 2%;
    margin-left: 50%;
    /* margin-top: -30%; */
  }
  .movie-btn:hover {
    background-color: #000;
    color: white;
  }
.pics {
    
  }
.pics .img {
    width: 100%;
    /* object-fit: fill; */
    
}
.movie_detail {
  position: absolute;
  width: 100%;

  height:30%;
  display: none;
  bottom: 0px;
  transition: 1s;
  z-index:4;
  background-image: -webkit-linear-gradient(transparent 30%, rgb(8 7 6 / 99%) 99%);

  /* text-align: center; */
}
.movie_imgs{
  position:relative;
}

.movie_imgs:hover + .movie_detail{
 
 
  
  display: inline;

}

.floating-movie-btn{
  position:absolute;
  width:100%;
  bottom: 0px;
  background-image: -webkit-linear-gradient(transparent 30%, rgb(8 7 6 / 99%) 99%);
}
.movie_detail_title{
  font-size: 40px;
  position: absolute;
    bottom: 75px;
}
.movie_detail_date{
  font-size: 30px;
  position: absolute;
    bottom: 55px;
}



@media screen and (min-width: 2268px ) and (max-width: 4535px) {

    .news-title {
      font-size: 100px;
      color: #FF8000;
      margin-left: 4%;
      /* margin: 10%; */
    }
    .movie_detail_title{
      font-size: 20px;
    }
    .movie_detail_date{
      font-size: 15px;
    }
  }
@media screen and (min-width: 1024px ) and (max-width: 2268px) {

    .movie-title {
      font-size: 100px;
      color: #FF8000;
      margin-left: 10%;
      /* margin: 10%; */
    }
    .movie_detail_title{
      font-size: 20px;
    }
    .movie_detail_date{
      font-size: 15px;
    }
  }

@media screen and (min-width:993px) and (max-width: 1024px) {
    .movie-title {
        font-size: 100px;
        color: #FF8000;
        margin-left: 10%;
        /* padding-top: 10%; */
    }
}
@media screen and (min-width:769px) and (max-width: 992px) {
    .movie-title {
        font-size: 100px;
        color: #FF8000;
        margin-left: 10%;
        /* padding-top: 25%; */
        /* margin-top: -1%; */
    }
}
@media screen and (min-width: 577px) and (max-width: 768px) {
    .movie-title {
        font-size: 60px;
        color: #FF8000;
        margin-left: 10%;
    }
}
@media screen and (min-width:270px) and (max-width: 576px) {
    .movie-title {
        font-size: 50px;
        color: #FF8000;
        margin-left: 10%;
    }
}