.about-row {
    margin-left: 4%;
    width: 100%;
    
}
.about-title {
    color: #FF8000;
    /* font-weight: bold; */
    font-size: 30px;
    padding-top: 10%;
    /* margin-top: -40px; */
}
.about-desc {
    font-family: "ImagoTTBoo";
    color: white;
    font-size: 20px;
    width: 95%;
    text-align: justify;
}
.desc2 {
    font-family: "ImagoTTBoo";
    color: white;
    font-size: 20px;
    /* width: 165%; */
}

.about-image {
    width: auto;
    margin-right: 10%;
}
.about-btn {
    margin-top: 4%;
}



@media screen and (min-width: 3831px ) and (max-width: 6000px) {

    .about-row {
        margin-left: 10%;
        width: 80%;   
    }
    .about-desc {
        font-size: 55px;

    }
    .desc2 {
        font-family: "ImagoTTBoo";
        color: white;
        font-size: 55px;
        width: 135%;
    }
 
  }
  .svgIcon {
    height: 50%;
}
@media screen and (min-width: 2268px ) and (max-width: 3831px) {

    .about-row {
        margin-left: 10%;
        width: 80%;
    }
    .about-desc {
        font-size: 20px;

    }
    .desc2 {
        font-family: "ImagoTTBoo";
        color: white;
        font-size: 20px;
        width: 120%;
    }

   
  }
  @media screen and (min-width: 1850px  ) and (max-width: 2268px) {

    .about-row {
        margin-left: 10%;
        width: 83%;   
    }
    .desc2 {
        font-family: "ImagoTTBoo";
        color: white;
        font-size: 20px;
        width: 125%;
    }
  }

  @media screen and (min-width: 1700px  ) and (max-width: 1850px) {

    .about-row {
        margin-left: 10%;
        width: 83%;   
    }
    .desc2 {
        font-family: "ImagoTTBoo";
        color: white;
        font-size: 20px;
        width: 135%;
    }
  }
  @media screen and (min-width: 1300px  ) and (max-width: 1700px) {

    .about-row {
        margin-left: 10%;
        width: 83%;   
    }
    .desc2 {
        font-family: "ImagoTTBoo";
        color: white;
        font-size: 20px;
        width: 150%;
    }
    
  }
  @media screen and (min-width:1200px) and (max-width: 1300px) {
    .about-row {
        margin-left: 10%;
        width: 80%;   
    }
    .desc2 {
        font-family: "ImagoTTBoo";
        color: white;
        font-size: 20px;
        width: 160%;
    }
}
@media screen and (min-width:1024px) and (max-width: 1200px) {
    .about-row {
        margin-left: 10%;
        width: 100%;   
    }
    .desc2 {
        font-family: "ImagoTTBoo";
        color: white;
        font-size: 20px;
        width: 165%;    
    }
}

@media screen and (min-width:769px) and (max-width: 1023px) {
    .about-image {
        display: none;
    }
    .about-row {
        margin-left: 10%;
        width: 100%;
        
    }
    .desc2 {
        font-family: "ImagoTTBoo";
        color: white;
        font-size: 20px;
        /* width: 190%; */
    }
}
@media screen and (min-width: 577px) and (max-width: 768px) {
    .about-image {
        display: none;
    }
    .about-row {
        margin-left: 10%;
        width: 100%;
        
    }
}
@media screen and (min-width: 280px) and (max-width: 576px) {
    .about-image {
        display: none;
    }
    .about-row {
        margin-left: 10%;
        width: 100%;
        
    }
}