.news-title {
    font-size: 100px;
    color: #FF8000;
    margin: 3%;
}
/* .collection img {
    filter: grayscale(100%);
    transition: 0.5s;
  } */

  .detailss{
    position: absolute;
    width: 100%;
  
    height: 30%;

    display: none;
    bottom: 0px;
    transition: 0.6s;
    z-index:4;
    transition: 1s;
    background-image: -webkit-linear-gradient(transparent 30%, rgba(6, 6, 5, 0.596) 99%); 
    border-radius:12px;
    padding:10px;
  }

  .img_containers{
    position:relative;
    width:  95%;
    /* padding:15px;  */
    border-radius:12px;
  }

  .img {
    /* position: relative; */
   width:100%;
    height: 100%;
  }
  .news .items {
    padding: 20px;
    border-radius: 5px;
    transition: 0.5s ease-in-out;
    width: 100%;
    /* margin-left: -30%; */
}

  .news_slider {

    object-fit: fill;
    border-radius: 12px;
    width:  100%;

    
  }

  .news_slider:hover + .detailss{
 
   
    display: inline;

  }

  .news_detail {
    font-size: 20px;
    text-align: center;
  }

  .slick_slider{
    padding-left:25px;
    padding-right:10px;
    position: relative;
  }

  .slick_slider_button{
    position: absolute;
    bottom: 38%;
    right: 10%;
    width: auto;

  }

  @media screen and (min-width: 2732px ) and (max-width: 5835px) {

    .news-title {
      font-size: 75px;
      color: #FF8000;
      margin-left: 4%;
      /* margin: 10%; */
    }
    .news_slider{
      height:720px;
    }
    .news_detail {
      font-size: 45px;
    }
  }

  @media screen and (min-width: 2268px ) and (max-width: 2732px) {

    .news-title {
      font-size: 75px;
      color: #FF8000;
      margin-left: 4%;
      /* margin: 10%; */
    }
    .news_slider{
      height:720px;
    }
    .news_detail {
      font-size: 35px;
    }
  }
  @media screen and (min-width: 1024px ) and (max-width: 2268px) {

    .news-title {
      font-size: 75px;
      color: #FF8000;
      margin-left: 10%;
      /* margin: 10%; */
    }
    .news_slider{
      height:300px;
    }

  }
@media screen and (min-width: 993px) and (max-width: 1024px) {

  .news-title {
    font-size: 75px;
    color: #FF8000;
    margin-left: 10%;
    /* margin: 10%; */
  }
  .news_slider{
    height:195px;
  }

}
@media screen and (min-width:769px) and (max-width: 992px) {
  .news-title {
    font-size: 100px;
    color: #FF8000;
    margin-left: 10%;
    /* margin: 25%; */
  }
  .news_slider{
    height:175px;
  }

}
@media screen and (min-width: 577px) and (max-width: 768px) {
  .news-title {
    font-size: 60px;
    color: #FF8000;
    margin-left: 10%;
  }
  .slick_slider_button{
    bottom: 22%;
  }
  .news_slider{
    height:120px;
  }
}
@media screen and (min-width:270px) and (max-width: 576px) {
  .news-title {
    font-size: 50px;
    color: #FF8000;
    margin-left: 10%;
  } 
 
  .news_slider{
    height:90px;
  }
 
  .slick_slider_button{
    bottom: 22%;
  }
}

