footer {
    background-color: transparent;
    color: #FF8000;
    margin-top: 0px;
  }
  
  footer .icon i {
    color: black;
    margin: auto;
  }
  .mainfooter {
    margin-left: -55%;
  }
  .socialicon {
    margin-top: -1%;
    margin-left: 65%;
  }
  .hrline {
    flex: 1;
    color: #FF8000;
   height: "2px";
   border: "none";
   width: 80%;
   margin-left: 20%;
  }
  .legal {
    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: transparent;
    color: #FF8000;
    margin-top: 0%;
}
  /* .legal p {
    opacity: 0.5;
  } */
  @media screen and (min-width:993px) and (max-width: 1024px) {
    footer {
      background-color: transparent;
      color: #FF8000;
      margin-top: 0px;
    }
  }
  @media screen and (min-width:769px) and (max-width: 992px) {
    footer {
      background-color: transparent;
      color: #FF8000;
      margin-top: 0px;
    }
  }
  @media screen and (min-width:577px) and (max-width: 768px) {
    footer .grid {
      grid-template-columns: repeat(2, 1fr);
    }
    footer {
      background-color: transparent;
      color: #FF8000;
      margin-top: 50px;
    }
    
    footer .icon i {
      color: black;
      margin: auto;
    }
    .legal {
      text-align: center;
      padding-top: 5px;
      padding-bottom: 18px;
      background-color: transparent;
      color: #FF8000;
      margin-top: -7%;
    }
    .mainfooter {
      margin-left: 0%;
      margin-top: 0%;
    }
    .socialicon {
      margin-top: 0%;
      margin-left: 0%;
    }
    .hrline {
     margin-left: 10%;
    }
    
  }
  @media screen and (min-width:270px) and (max-width: 576px) {
    footer .grid {
      grid-template-columns: repeat(2, 1fr);
    }
    footer {
      background-color: transparent;
      color: #FF8000;
      margin-top: 40px;
    }
    footer .icon i {
      color: black;
      margin: auto;
    }
    .mainfooter {
      margin-left: 0%;
      margin-top: 0%;
    }
    .socialicon {
      margin-top: 0%;
      margin-left: 0%;
    }
    .legal {
      text-align: center;
      padding-top: 5px;
      padding-bottom: 18px;
      background-color: transparent;
      color: #FF8000;
      margin-top: -14%;
    }
    .hrline {
      flex: 1;
      color: #FF8000;
     height: "2px";
     border: "none";
     width: 90%;
     margin-left: 5%;
    }
  }
  /* @media screen and (max-width: 820px) {
    footer {
      background-color: transparent;
      color: #FF8000;
      margin-top: 32px;
    }
  }*/
 
  