.image-heading {
    /* background-image: url("../../../public/images/image-heading.jpg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat; */
    background-color: transparent;
    color: #FF8000;
    margin-top:6%;
    margin-bottom:12%;
    /* height: 50vh;
    line-height: 30vh;
    position: relative; */
    /* z-index: -2; */
  }
  
  .image-heading::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    background: transparent;
    height: 50vh;
    width: 100%;
    z-index: -1;
  }
  .image-heading h1 {
    font-size: 147px;
    text-transform: capitalize;
    text-align: center;
    /* padding-top: 8%; */
  }

@media screen and (min-width:993px) and (max-width: 1024px) {
  .image-heading {
    background-color: transparent;
    color: #FF8000;
    /* height: 42vh;
    line-height: 30vh;
    position: relative; */
    /* z-index: -2; */
  }
  .image-heading h1 {
    font-size: 147px;
    text-transform: capitalize;
    text-align: center;
    /* padding-top: 8%; */
  }
}
@media screen and (min-width:769px) and (max-width: 992px) {
  .image-heading {
    background-color: transparent;
    color: #FF8000;

    /* height: 25vh;
    line-height: 30vh;
    position: relative; */
    /* z-index: -2; */
  }
  .image-heading h1 {
    font-size: 147px;
    text-transform: capitalize;
    text-align: center;
    /* padding-top: 15%; */
  }
}
@media screen and (min-width:577px) and (max-width: 768px) {
  .image-heading {
    background-color: transparent;
    color: #FF8000;
    margin-bottom:16%;
    /* height: 22vh;
    line-height: 30vh;
    position: relative; */
    /* z-index: -2; */
  }
  .image-heading h1 {
    font-size: 147px;
    text-transform: capitalize;
    text-align: center;
    /* padding-top: 8%; */
  }
}
@media screen and (min-width:270px) and (max-width: 576px) {
  .image-heading {
    background-color: transparent;
    color: #FF8000;
    margin-bottom:16%;
    /* height: 18vh;
    line-height: 30vh;
    position: relative; */
    /* z-index: -2; */
  }
  .image-heading h1 {
    font-size: 50px;
    text-transform: capitalize;
    text-align: center;
    /* padding-top: 20%; */
  }
}