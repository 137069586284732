* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.navbar {
  /* position: absolute; */
  z-index: 4;
  /* position: sticky; */

  justify-content: space-between;
  background-color: black;
  color: white;
  width: 100%;
  height:100px
}

.navbar.fixed {
  background-color: black;
  position: fixed;
  top: 0;
  left: 0;

  height:100px;
}
.logo {
  width: 30%;
  margin-top: -20px
}

.nav-links {
  display: flex;
  position: absolute;
  justify-content: flex-end;
  list-style: none;
  width: 100%;

}

.home,
.about,
.movie,
.original,
.news,
.contact {
  font-family: "Helvetica";

  text-decoration: none;
  color: white;
  font-size: 20px;
  padding: 10px 20px;
  margin: 0px 10px;
  cursor: pointer;
}
.nav-links a:hover {
  color: orange;
}

.mobile-menu-icon {
  display: none;
}

@media screen and (min-width:1025px) and (max-width: 1280px) {

  .nav-links {
    display: flex;
    justify-content: flex-end;
    list-style: none;
    width: 100%;
    /* margin-top: -13%;
    margin-left: -15%; */
  }
}
@media screen and (min-width:993px) and (max-width: 1024px) {
  .logo {
    display: flex;
    position: absolute;
    top: 0px;
    left: 35px;
    width: 40%;
    margin-top: -6px;
  }

  .nav-links {
    display: none;
  }

  .nav-links-mobile {
    background-color: black;
    display: block;
    left: 0;
    list-style: none;
    position: absolute;
    top: 75px;
    transition: all .5s ease-out;
    width: 100%;
    z-index: 4;
  }

  .home,
  .about,
  .movie,
  .original,
  .news,
  .contact {
    color: white;
    text-align: center;
    padding: 32px;
    width: 100%;
    transition: all 0.5s ease;
  }

  .mobile-menu-icon {
    display: block;
    position: absolute;
    font-size: 30px;
    color: white;
    background-color: transparent;
    border: none;
    outline: none;
    top: 15px;
    right: 25px;
  }
}
@media screen and (min-width:769px) and (max-width: 992px) {
  .logo {
    display: flex;
    position: absolute;
    top: 15px;
    left: 35px;
    width: 40%;

      margin-top: 10px
  
  }
  .navbar {
    /* position: absolute; */
    z-index: 4;
    /* position: sticky; */
    justify-content: space-around;
    align-items: center;
    height: 100px;
    background-color: black;
    color: white;
    width: 100%;
  }
  .nav-links {
    display: none;
  }

  .nav-links-mobile {
    background-color: black;
    display: block;
    left: 0;
    list-style: none;
    position: absolute;
    top: 75px;
    transition: all .5s ease-out;
    width: 100%;
    z-index: 4;
  }

  .home,
  .about,
  .movie,
  .original,
  .news,
  .contact {
    color: white;
    text-align: center;
    padding: 32px;
    width: 100%;
    transition: all 0.5s ease;
  }

  .mobile-menu-icon {
    display: block;
    position: absolute;
    font-size: 30px;
    color: white;
    background-color: transparent;
    border: none;
    outline: none;
    top: 15px;
    right: 25px;
  }
}
@media screen and (min-width:577px) and (max-width: 768px) {
  .logo {
    display: flex;
    position: absolute;
    top: 0px;
    left: 35px;
    margin-top: 10px
    /* width: 100%; */
  }

  .nav-links {
    display: none;
  }

  .nav-links-mobile {
    background-color: black;
    display: block;
    left: 0;
    list-style: none;
    position: absolute;
    top: 75px;
    transition: all .5s ease-out;
    width: 100%;
    z-index: 4;
  }

  .home,
  .about,
  .movie,
  .original,
  .news,
  .contact {
    color: white;
    text-align: center;
    padding: 32px;
    width: 100%;
    transition: all 0.5s ease;
  }

  .mobile-menu-icon {
    display: block;
    position: absolute;
    font-size: 30px;
    color: white;
    background-color: transparent;
    border: none;
    outline: none;
    top: 15px;
    right: 25px;
  }
}
@media screen and (min-width:270px) and (max-width: 576px) {
  .logo {
    display: flex;
    position: absolute;
    top: -15px;
    left: 0px;
    margin-top: 10px
    /* width: 100%; */
  }

  .nav-links {
    display: none;
  }

  .nav-links-mobile {
    background-color: black;
    display: block;
    left: 0;
    list-style: none;
    position: absolute;
    top: 75px;
    transition: all .5s ease-out;
    width: 100%;
    z-index: 4;
  }

  .home,
  .about,
  .movie,
  .original,
  .news,
  .contact {
    color: white;
    text-align: center;
    padding: 32px;
    width: 100%;
    transition: all 0.5s ease;
  }

  .mobile-menu-icon {
    display: block;
    position: absolute;
    font-size: 30px;
    color: white;
    background-color: transparent;
    border: none;
    outline: none;
    top: 15px;
    right: 25px;
  }
}

/* @media screen and (max-width: 820px) {
  .logo {
    display: flex;
    position: absolute;
    top: 15px;
    left: 35px;
    width: 40%;
  }

  .nav-links {
    display: none;
  }

  .nav-links-mobile {
    background-color: black;
    display: block;
    left: 0;
    list-style: none;
    position: absolute;
    top: 75px;
    transition: all .5s ease-out;
    width: 100%;
    z-index: 4;
  }

  .home,
  .about,
  .movie,
  .original,
  .news,
  .contact {
    color: white;
    text-align: center;
    padding: 32px;
    width: 100%;
    transition: all 0.5s ease;
  }

  .mobile-menu-icon {
    display: block;
    position: absolute;
    font-size: 30px;
    color: white;
    background-color: transparent;
    border: none;
    outline: none;
    top: 15px;
    right: 25px;
  }
}
 */