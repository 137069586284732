
.img {
    width: 100%;
  }
   
   .item {
    
    /* margin: -5%; */
  }
  .image_slide {
    width: 100%;
    height: 100%;
  }
   .imgae {
    position: relative;
  }
   .imgae i {
    position: absolute;
    bottom: 0;
    color: white;
    margin: 20px;
  }
   .imgae i label {
    margin-left: 10px;
    font-weight: normal;
  }
   .Movie-details {
    /* text-align: left; */
    padding-left: 10%;
    margin-top: -5%;
  }
   .rate {
    display: flex;
    justify-content: center;
    margin-top: 10px;
    font-size: 13px;
    color: grey;
  }
   .rate i {
    margin: 2px;
  }
   .cards h2 {
    font-weight: 500;
    font-size: 20px;
    color: white;
    position: relative;
    margin-bottom: 30px;
  }
   /* .cards h2::after {
    position: absolute;
    content: "";
    bottom: -10px;
    left: 45%;
    height: 1px;
    width: 30px;
    background: black;
  }
   .cards h2::before {
    position: absolute;
    content: "";
    bottom: -20px;
    left: 40%;
    height: 1px;
    width: 60px;
    background: black;
  } */
   .cards h3 {
    color: white;
    font-weight: 500;
  }
   .cards h3 span {
    color: grey;
    font-weight: 300;
    font-size: 15px;
  }
   .content {
    margin-bottom: 0px;
  }
  /*----- control btn -------*/
   .control-btn .next,
   .control-btn .prev {
    top: 100%;
    background: none;
  }
   .control-btn .prev {
    left: 45%;
  }
   .control-btn .next {
    right: 45%;
  }
   .control-btn .prev i,
   .control-btn .next i {
    background: rgba(244, 6, 6, 0.05);
    color: #FF8000;
    font-size: 15px;
    transition: 0.5s;
  }
   .control-btn .prev i:hover,
   .control-btn .next i:hover {
    background: darkred;
    color: white;
  }
  /*----- control btn -------*/
  @media screen and (max-width: 768px) {
     .control-btn .prev {
      left: 40%;
    }
     .control-btn .next {
      right: 42%;
    }
  }