.originals_ori_cast{
    margin-left: 10%;

    margin-right:10px;
}
.main_ori_cast{
    color: #FF8000;
    font-size: 30px;
}
.ori_cast_grid{
    display: flex;
    /* grid-template-columns: repeat(3, 1fr);
    grid-gap: 85px; */
    flex-wrap: wrap;

    /* grid-template-columns: repeat(auto-fit, minmax(250px,1fr)); */
}
.ori_items{
    display: inline-grid;
    justify-content: center;
    padding:25px;
}
.ori_cast_details{
        color: #fff;
        text-align: center;
        margin-top: 0%;
        /* margin-left: -12%; */
    }
.ori_cast_rows {
    display: flex;
}
.ori_cast_columns {
    flex: 50%;
    color: #fff;
    padding-left: 10px;
    /* height: 300px; */
}
.ori_img {
    position: relative;
    height: 75%;
}
@media screen and (min-width:577px) and (max-width: 768px) {
    .originals_ori_cast{
        margin-left: 5%;
    
    }
    .ori_cast_grid{
        /* display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 35px; */
        width: 100%;
    display: flex;
    flex-wrap: wrap;
   
        /* grid-template-columns: repeat(auto-fit, minmax(250px,1fr)); */
    }
    .ori_img {
        position: relative;
        height: 75%;
    }
    .ori_cast_details {
        color: #fff;
        text-align: center;
        margin-top: 5%;
        margin-left: -25%;
    }
    .img_line {
        width: 100%;
    }
}